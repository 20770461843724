<template>
  <div>
    <div v-if="!global.error && !global.success">
      <h2>Want to join?</h2>
      <RaInput
        v-model="form.name"
        label="Your name"
        placeholder="Type here"
        feedback="To introduce"
        :success="success.name"
        :error="error.name"
        :disabled="global.disabled"
        @resetValidation="success.name = false; error.name = false"
      />

      <RaInput
        v-model="form.contact"
        label="How can we contact with you?"
        placeholder="Any contact info (with instruction)"
        feedback="Email or Telegram, or Discord, or any other what do you prefer more"
        :success="success.contact"
        :error="error.contact"
        :disabled="global.disabled"
        @resetValidation="success.contact = false; error.contact = false"
      />

      <RaInput
        v-model="form.status"
        label="Whats your profession?"
        placeholder="Or specialization"
        feedback="We really interesting"
        :success="success.status"
        :error="error.status"
        :disabled="global.disabled"
        @resetValidation="success.status = false; error.status = false"
      />

      <RaInput
        v-model="form.github"
        label="Your Github profile"
        placeholder="Link or nickname here"
        feedback="Then we will send invite to GitHub and Discord"
        :success="success.github"
        :error="error.github"
        :disabled="global.disabled"
        @resetValidation="success.github = false; error.github = false"
      />

      Psychotype code
      <br />
      Get it here:
      <RaLink
        href="https://www.16personalities.com/free-personality-test"
        styled
        no-wrap
      >
        16personalities.com
      </RaLink>

      <RaInput
        v-model="form.psychotype"
        label=""
        placeholder="Link to result or code (ENFJ-A for example)"
        feedback="Thank you for that"
        :success="success.psychotype"
        :error="error.psychotype"
        :disabled="global.disabled"
        @resetValidation="success.psychotype = false; error.psychotype = false"
      />

      <RaCheckbox
        v-if="false"
        v-model="form.options.payed"
        label="I want a personal lessons ($)"
        refname="payed"
      />

      <RaButton
        text="Send invite request"
        :disabled="global.disabled"
        @click="onSubmit"
      />
    </div>

    <div v-if="global.success">
      <h3>Congrats!</h3>
      <p>We will contact with you soon</p>

      <RaButton
        text="Back"
        type="success"
        :disabled="global.disabled"
        @click="global.success = false"
      />
    </div>

    <div v-if="global.error">
      <h3>Error!</h3>
      <p>Some backEnd error, try again</p>

      <RaButton
        text="Try again"
        type="danger"
        :disabled="global.disabled"
        @click="global.error = false"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Fingerprint2 from 'fingerprintjs2'

import RaButton from '@/components/elements/RaButton.vue'
import RaLink from '@/components/elements/RaLink.vue'
import RaInput from '@/components/elements/RaInput.vue'
import RaCheckbox from '@/components/elements/RaCheckbox.vue'

export default {
  name: 'TrainingForm',

  components: {
    RaButton,
    RaLink,
    RaInput,
    RaCheckbox
  },

  data: () => ({
    userId: '',

    global: {
      success: false,
      error: false,
      disabled: false
    },

    form: {
      name: '',
      contact: '',
      status: '',
      github: '',
      psychotype: '',
      options: {
        payed: false
      }
    },

    success: {
      name: false,
      contact: false,
      status: false,
      github: false,
      psychotype: false
    },

    error: {
      name: false,
      contact: false,
      status: false,
      github: false,
      psychotype: false
    }
  }),

  mounted() {
    this.initFingerId()
  },

  methods: {
    onSubmit() {
      this.proceedValidation()
    },

    proceedValidation() {
      this.success = {
        name: false,
        contact: false,
        status: false,
        github: false,
        psychotype: false
      }

      this.error = {
        name: false,
        contact: false,
        status: false,
        github: false,
        psychotype: false
      }

      let resume = true

      if (
        this.form.name
        && this.form.name !== ''
        && this.form.name.length > 1
      ) {
        this.success.name = true
      } else {
        this.error.name = true
        resume = false
      }

      if (
        this.form.contact
        && this.form.contact !== ''
        && this.form.contact.length > 1
      ) {
        this.success.contact = true
      } else {
        this.error.contact = true
        resume = false
      }

      if (
        this.form.status
        && this.form.status !== ''
        && this.form.status.length > 1
      ) {
        this.success.status = true
      } else {
        this.error.status = true
        resume = false
      }

      if (
        this.form.github
        && this.form.github !== ''
        && this.form.github.length > 1
      ) {
        this.success.github = true
      } else {
        this.error.github = true
        resume = false
      }

      if (
        this.form.psychotype
        && this.form.psychotype !== ''
        && this.form.psychotype.length > 1
      ) {
        this.success.psychotype = true
      } else {
        this.error.psychotype = true
        resume = false
      }

      if (resume) {
        this.global.disabled = true
        setTimeout(() => {
          this.apiRequest()
        }, 500)
      }
    },

    proceedSuccess() {
      this.form = {
        name: '',
        contact: '',
        status: '',
        github: '',
        psychotype: '',
        options: {
          payed: false
        }
      }

      this.success = {
        name: false,
        contact: false,
        status: false,
        github: false,
        psychotype: false
      }

      this.error = {
        name: false,
        contact: false,
        status: false,
        github: false,
        psychotype: false
      }

      this.global.error = false
      this.global.success = true
      this.global.disabled = false
    },

    proceedError() {
      this.form = {
        name: '',
        contact: '',
        status: '',
        github: '',
        psychotype: '',
        options: {
          payed: false
        }
      }

      this.success = {
        name: false,
        contact: false,
        status: false,
        github: false,
        psychotype: false
      }

      this.error = {
        name: false,
        contact: false,
        status: false,
        github: false,
        psychotype: false
      }

      this.global.error = true
      this.global.success = false
      this.global.disabled = false
    },

    async apiRequest() {
      await axios.post('https://api.ralmaz.pro/training/request', {
        name: this.form.name,
        contact: this.form.contact,
        status: this.form.status,
        github: this.form.github,
        psychotype: this.form.psychotype,
        payed: this.form.options.payed ? 'Yes' : 'No',
        user: this.userId
      })
        .then((response) => {
          if (response.data === 'success') {
            this.proceedSuccess()
          } else if (response.data === 'already') {
            this.$toast.open({
              message: 'You have already sended it before',
              type: 'info'
            })
          } else {
            this.proceedError()
          }
        })
        .catch(() => {
          this.proceedError()
        })
    },

    initFingerId() {
      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          Fingerprint2.getV18((id) => {
            this.userId = id
          })
        })
      } else {
        setTimeout(() => {
          Fingerprint2.getV18((id) => {
            this.userId = id
          })
        }, 500)
      }
    }
  }
}
</script>

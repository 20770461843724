<template>
  <div
    :class="{
      'input-success': success,
      'input-error': error
    }"
    class="input__wrapper"
  >
    <label
      v-if="!textarea"
      class="label-form"
      v-text="label"
    />
    <div :class="{'input__validator': error || success}">
      <input
        v-if="!textarea"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        type="text"
        class="input-form"
        @input="changeInput"
      />
      <div class="input__validator__status" />
    </div>
    <div
      v-if="textarea"
      class="input__wrapper"
    >
      <label
        class="label-form"
        v-text="label"
      />
      <textarea
        type="text"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="changeInput"
      />
    </div>
    <p
      class="feedback-form"
      v-text="feedback"
    />
  </div>
</template>

<script>
export default {
  name: 'RaInput',

  props: {
    value: {
      type: String,
      default: () => ''
    },

    label: {
      type: String,
      default: () => ''
    },

    success: {
      type: Boolean
    },

    error: {
      type: Boolean
    },

    placeholder: {
      type: String,
      default: () => ''
    },

    disabled: {
      type: Boolean
    },

    textarea: {
      type: Boolean
    },

    feedback: {
      type: String,
      default: () => ''
    }
  },

  methods: {
    changeInput(e) {
      this.$emit('input', e.target.value)
      this.$emit('resetValidation')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .input__wrapper {
    margin-bottom: 30px;
  }

  .label-form {
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
  }

  input.input-form,
  select,
  textarea {
    height: 50px;
    font-size: 16px;
    border: 2px solid $grayShade3;
    width: 100%;
    max-width: 450px;
    padding: 12px;
    font-family: "Poppins";
    border-radius: $borderRadius;
    color: $foregroundColor;
    background: $backgroundColor;
    &:focus,
    &.active {
      outline: none;
      border-color: $primaryColor;
    }
    &:disabled {
      cursor: not-allowed;
      background: $grayShade1;
      opacity: 0.6;
    }
  }

  textarea {
    resize: vertical;
    min-height: 117px;
  }

  select {
    height: 45px;
    padding: 8px 12px;
    cursor: pointer;
    option {
      font-weight: 300;
    }
  }

  .input__validator {
    position: relative;
    &__status {
      position: absolute;
      border-radius: 50%;
      background: purple;
      width: 30px;
      height: 30px;
      left: -35px;
      top: 10px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 18px;
        border-radius: 40px;
        background: $backgroundColor;
      }
    }
  }

  .input-error {
    input,
    select,
    textarea {
      border-color: $dangerColor;
    }
    .feedback-form {
      color: $dangerRedShade3;
    }
    .input__validator__status {
      background: $dangerColor;
      &:before {
        transform: rotate(45deg) translateY(-5.5px) translateX(13.5px);
      }
      &:after {
        transform: rotate(-45deg) translateY(13.5px) translateX(5.5px);
      }
    }
  }

  .input-success {
    input,
    select,
    textarea {
      border-color: $successColor;
    }
    .feedback-form {
      color: $successGreenShade4;
    }
    .input__validator__status {
      background: $successColor;
      &:before {
        transform: rotate(45deg) translateY(-6px) translateX(16px);
      }
      &:after {
        height: 7px;
        transform: rotate(-45deg) translateY(16px) translateX(-5px);
      }
    }
  }

  .feedback-form {
    margin-top: 5px;
    font-size: 14px;
    color: $grayShade3;
  }

  .ra-body-light {
    .feedback-form {
      color: $black;
    }

    .input-form {
      background-color: $foregroundColor;
      color: $black;
    }
  }
</style>

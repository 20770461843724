<template>
  <div class="checkbox">
    <input
      :id="refname"
      :value="value"
      type="checkbox"
      @change="changeInput"
    />
    <label :for="refname">
      {{ label }}
      <span class="box" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'RaCheckbox',

  props: {
    value: {
      type: Boolean
    },

    label: {
      type: String,
      default: () => ''
    },

    refname: {
      type: String,
      default: () => 'checkbox'
    }
  },

  methods: {
    changeInput() {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  input:disabled {
    ~ * {
      opacity: 0.3;
      user-select: none;
      pointer-events: none;
    }
  }

  .checkbox {
    position: relative;
    user-select: none;
    margin-bottom: 10px;
    input {
      display: none;
    }
    label {
      font-size: 18px;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      font-weight: 500;
      padding-left: 35px;
    }
    span.box {
      display: inline-block;
      width: 20px;
      border-radius: $borderRadius;
      border: 1px solid $grayColor;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      margin-right: 3px;
      transition: 0.3s ease;
      position: absolute;
      left: 0;
      top: -1px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 16px;
        border-radius: 40px;
        background: $backgroundColor;
        transition: all 0.3s ease;
      }
      &:before {
        transform: rotate(45deg) translateY(-5px) translateX(10px) scale(0);
      }
      &:after {
        height: 8px;
        transform: rotate(-45deg) translateY(10px) translateX(-4px) scale(0);
      }
    }
    input:checked {
      + label {
        span.box {
          background: $primaryColor;
          border-color: $primaryColor;
          &:before {
            transform: rotate(45deg) translateY(-5px) translateX(10px) scale(1);
          }
          &:after {
            height: 8px;
            transform: rotate(-45deg) translateY(10px) translateX(-4px) scale(1);
          }
        }
      }
    }
    input:disabled:checked + span.box {
      background: $grayColor;
      border: $grayColor;
    }
    input:disabled:checked ~ label {
      &:before,
      &:after {
        background: black;
      }
    }
  }
</style>

<template>
  <div class="ra-basic ra-basic-text">
    <br />
    <div class="ra-academy-logo">
      <img
        class="ra-academy-logo-light"
        src="@/assets/academy/logo-light.png"
      >
      <img
        class="ra-academy-logo-dark"
        src="@/assets/academy/logo-dark.png"
      >
    </div>
    <br />
    <div>
      <p>
        Providing <b>free lessons</b> in <b>JavaScript, Vue, Electron, WebRTC</b> domains for people
        who want to change own profession or want to improve their knowledge
        <br />
        For people who have some developer experience 
        as well as for those who don't have any experience and start from nothing
      </p>

      <p>
        Our strategy and goal - great training
        for people who deserve this<br />
        Every day people with practice in the community,
        are becoming better and better in development
        and soft-skills
      </p>

      <p>
        We are <b>soft-oriented</b>
        community who train people to have soft skills as good as hard skills
      </p>

      <div>
        <b>Partners</b>
        <br />
        <br />
        <div class="ra-partners-listing">
          <div class="ra-partners-item">
            <RaLink
              href="https://yodvisor.com/"
              follow
              no-wrap
            >
              <img
                src="@/assets/logos/logo-purple-light.svg"
                width="300"
                alt="Yodvisor"
              >
            </RaLink>
          </div>
          <div class="ra-partners-item">
            <RaLink
              href="https://qlt.kz"
              follow
              no-wrap
            >
              <img
                src="@/assets/academy/qlt.jpg"
                height="114"
                alt="QLT"
              >
            </RaLink>
          </div>
          <div class="ra-partners-item">
            <RaLink
              href="https://solardigital.com.ua/"
              follow
              no-wrap
            >
              <img
                src="@/assets/academy/solar.png"
                width="320"
                alt="SOLAR"
              >
            </RaLink>
          </div>
        </div>
        <br />
        <br />
      </div>

      <p v-if="false">
        If you want to <b>join us</b> - fill the <b>form</b> below,
        and we will review it and discuss with team memebers send an
        <b>invite</b> to you
      </p>

      <p v-if="false">
        Languages we speak inside community:
        <b>English</b> 20%,
        <b>Russian</b> 80%
      </p>
    </div>

    <div v-if="false">
      <h2>Want to join?</h2>

      <p>
        Over the past three years, we have grown enough
        to understand our capabilities,
        so we stop receiving new applications from those wishing to join.
      </p>
    </div>

    <div>
      <TrainingForm />
    </div>

    <div
      v-if="false"
      class="ra-sponsor"
    >
      Become a sponsor:
      <br />
      <RaLink
        href="https://www.patreon.com/ralmaz"
        no-wrap
      >
        <img
          src="@/assets/academy/patreon.jpg"
          class="ra-patreon"
        >
      </RaLink>
    </div>
  </div>
</template>

<script>
import RaLink from '@/components/elements/RaLink.vue'
import TrainingForm from '@/components/blocks/TrainingForm.vue'

export default {
  name: 'Academy',

  components: {
    RaLink,
    TrainingForm
  },

  metaInfo: {
    title: 'Academy',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'Providing free Vue.js lessons'
      },
      { name: 'og:title', content: 'Veni Vidi Academy | Roman Almazov' },
      { name: 'twitter:title', content: 'Veni Vidi Academy | Roman Almazov' },
      {
        name: 'og:description',
        content: 'Providing free Vue.js lessons'
      },
      {
        name: 'twitter:description',
        content: 'Providing free Vue.js lessons'
      },
      { name: 'og:url', content: 'https://ralmaz.pro/academy' },
      { name: 'twitter:url', content: 'https://ralmaz.pro/academy' }
    ]
  }
}
</script>

<style lang="scss" scoped>
  .ra-partners-listing {
    display: grid;
    grid-template-columns: 300px 120px 320px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    .ra-partners-listing {
      grid-template-columns: 1fr;
    }
  }

  .ra-academy-logo {
    display: grid;
    justify-content: center;

    img {
      width: 100%;
      max-width: 576px;
    }
  }

  .ra-sponsor {
    padding-top: 60px;
  }

  .ra-patreon {
    width: 200px;
    margin-top: 15px;
  }

  .ra-partners-item {
    &:hover {
      filter: hue-rotate(30deg);
    }
  }
</style>
